/**
* Generated automatically at built-time (2025-01-31T09:54:52.338Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "c85b9cd4-df8b-46eb-acd5-93051a9bdfd1",templateKey: "sites/103-0b1b669b-1848-425d-8abe-334d886c5137"};