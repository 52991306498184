/**
* Generated automatically at built-time (2025-01-31T09:54:52.182Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "byg-et-udekokken",templateKey: "sites/103-ef93e3f1-d41c-4d5d-8968-9f2fa1ac0f56"};